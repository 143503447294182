import device from 'current-device';
export default function getUserAgent() {
  const currentDevice = device.noConflict();
  let ua;
  if (currentDevice.type === 'mobile') {
    ua = 'phone';
  } else if (currentDevice.type === 'tablet') {
    ua = 'pad';
  } else {
    ua = 'pc';
  }
  return ua;
}
