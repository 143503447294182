import _ from 'lodash';
import scssConfig from '@/assets/config.module.scss';
import getUrlParam from './getUrlParam';
const fail = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // Message('error','GPS_DENINED')
      // eslint-disable-next-line no-console
      console.log('用户拒绝对获取地理位置的请求。');
      break;
    case error.POSITION_UNAVAILABLE:
      // Message('error','GPS_UNAVAILABLE')
      // eslint-disable-next-line no-console
      console.log('位置信息是不可用的。');
      break;
    case error.TIMEOUT:
      // Message('error','GPS_TIMEOUT')
      // eslint-disable-next-line no-console
      console.log('请求用户地理位置超时。');
      break;
    case error.UNKNOWN_ERROR:
      // Message('error','GPS_UNKNOWN')
      // eslint-disable-next-line no-console
      console.log('未知错误。');
      break;
    default:
      // eslint-disable-next-line no-console
      console.log('其他。');
      break;
  }
};
// 获取定位坐标
export const getLocation = (successFun, failFun = fail) => {
  if (window.navigator.geolocation) {
    window.navigator.geolocation.getCurrentPosition(successFun, failFun, {
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: 10000
    });
  } else {
    // eslint-disable-next-line no-console
    console.log('该浏览器不支持获取地理位置。');
  }
};
// 解析url中的search参数为对象
export const urlParse = (url) => {
  let _url = decodeURI(url);
  let searchObj = null;
  let searchStr = _url.split('?')[1];
  if (searchStr) {
    let searchArr = searchStr.split('&');
    searchArr.forEach((item) => {
      let arr = item.split('=');
      if (searchObj) {
        searchObj[arr[0]] = arr[1];
      } else {
        searchObj = {
          [arr[0]]: arr[1],
        };
      }
    });
  }
  return searchObj;
};
// 为金额添加千分位逗号
export const commafy = (number) => {
  let num = number;
  if (Number(num) === 0 || isNaN(Number(num))) {
    return num;
  }
  const del = nm => {
    let n = nm;
    n = String(n);
    if (n.trim() === '') {
      return '';
    }
    n = n.replace(/,/gi, '');
    return n;
  };
  num = String(num);
  if (num.trim() === '') {
    return '';
  }
  num = del(num);
  if (isNaN(num)) {
    return '';
  }
  const re = /(-?\d+)(\d{3})/;
  if (/^.*\..*$/.test(num)) {
    const pointIndex = num.lastIndexOf('.');
    let intPart = num.substring(0, pointIndex);
    const pointPart = num.substring(pointIndex + 1, num.length);
    intPart = String(intPart);

    while (re.test(intPart)) {
      intPart = intPart.replace(re, '$1,$2');
    }
    num = `${intPart}.${pointPart}`;
  } else {
    num = String(num);
    while (re.test(num)) {
      num = num.replace(re, '$1,$2');
    }
  }
  return num;
};
/**
 * 匹配图片地址
 * @param {*} path 图片路径，例如：login/facebook.png
 * @returns 图片链接地址，例如：https://cdn-h5-html.gwmcloud.com/pcstore/login/facebook.png
 */
export const matchUrlImg = (path = '', env) => {
  // 作用域地址，例如：https://cdn-h5-html.gwmcloud.com/pcstore/
  let url;
  if (env === 'test') {
    url = scssConfig.testUrlImg;
  } else {
    url = scssConfig.urlImg;
  }
  const urlImg = url.replace(/"/g, '') + path;
  return urlImg;
};
export function isEmojiCharacter(substring) {
  for (let i = 0; i < substring.length; i++) {
    let hs = substring.charCodeAt(i);
    if (0xd800 <= hs && hs <= 0xdbff) {
      if (substring.length > 1) {
        let _ls = substring.charCodeAt(i + 1);
        let uc = ((hs - 0xd800) * 0x400) + (_ls - 0xdc00) + 0x10000;
        if (0x1d000 <= uc && uc <= 0x1f77f) {
          return true;
        }
      }
    } else {
      if (0x2100 <= hs && hs <= 0x27ff && hs !== 0x263b) {
        return true;
      } else if (0x2B05 <= hs && hs <= 0x2b07) {
        return true;
      } else if (0x2934 <= hs && hs <= 0x2935) {
        return true;
      } else if (0x3297 <= hs && hs <= 0x3299) {
        return true;
      } else if (hs === 0xa9 || hs === 0xae || hs === 0x303d || hs === 0x3030 ||
        hs === 0x2b55 || hs === 0x2b1c || hs === 0x2b1b ||
        hs === 0x2b50 || hs === 0x231a) {
        return true;
      }
    }
    if (substring.length > 1 && i < substring.length - 1) {
      let ls = substring.charCodeAt(i + 1);
      if (ls === 0x20e3) {
        return true;
      }
    }
  }
}

// 获取url中的车系和默认`
export const getCalModalNames = carBrandInUrl => {
  // 车型列表，有新的车系就在下面添加一条默认车型
  const baseInfo = [{
    carBrand: 'HF',
    audiCode: 'DSDH6',
    brandCode: 'HF',
  },
  {
    carBrand: 'OL',
    audiCode: 'ES11',
    brandCode: 'OL',
  },
  {
    carBrand: 'ORA',
    audiCode: 'ES11',
    brandCode: 'OL',
  }
  ];
  // 根据传入值筛选上方的基础车型列表baseInfo
  const realCarBrand = _.find(baseInfo, item => item.carBrand === carBrandInUrl);
  // 有值就取，没值就取baseInfo第0项，默认哈弗
  return realCarBrand > -1 ? realCarBrand : baseInfo[0];
};

// 随机数时间戳
export function uniqueId() {
  let a = Math.random;
  // eslint-disable-next-line no-var
  var b = parseInt;
  return Number(new Date()).toString() + b(10 * a()) + b(10 * a()) + b(10 * a());
}
/**
 * 校验字符串是否含有特殊字符
 * @param {String} newName 需要校验的字符串
 * @return {Boolean} false | true 返回一个布尔值
 */
export const hasSpecialChar = function (newName) {
  let regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
  let regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

  if (regEn.test(newName) || regCn.test(newName)) {
    return true;
  } else {
    return false;
  }
};
/**
 * 校验字符串是否为空【null undefined ''】三种情况
 * @param {String} str 需要校验的字符串
 * @return {Boolean} false | true 返回一个布尔值
 */
export const isEmpty = function (str) {
  // eslint-disable-next-line no-undefined
  if (str === null || str === undefined || str === '') {
    return true;
  } else {
    return false;
  }
};
/**
  * 返回当前的时间（年月日时分秒）
  */
export const getDateTime = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours() + 1;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  month = checkTime(month);
  day = checkTime(day);
  hour = checkTime(hour);
  minute = checkTime(minute);
  second = checkTime(second);
  function checkTime(i) {
    if (i < 10) {
      // eslint-disable-next-line no-param-reassign
      i = '0' + i;
    }
    return i;
  }
  return String(year) + '年' + month + '月' + day + '日' + hour + '时' + minute + '分' + second + '秒';
};
/**
* 获得滚动条的滚动距离
*/
export const getScrollOffset = () => {
  if (window.pageXOffset) {
    return {
      x: window.pageXOffset,
      y: window.pageYOffset
    };
  } else {
    return {
      x: document.body.scrollLeft + document.documentElement.scrollLeft,
      y: document.body.scrollTop + document.documentElement.scrollTop
    };
  }
};
// 正则表达式实现trim()去掉开始和结尾的空格
export const trimStr = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, '');
};
/**
 *
 * 验证数组是否有重复元素对数字数组，字符串数组都有效。
 */
export const hasRepeatValue = (a) => {
  // eslint-disable-next-line no-control-regex
  return /(\x0f[^\x0f]+)\x0f[\s\S]*\1/.test('\x0f' + a.join('\x0f\x0f') + '\x0f');
};
/**
 * Js获取页面地址参数
 */
export const getParameter = (param) => {
  let query = window.location.search;// 获取URL地址中？后的所有字符
  let iLen = param.length;// 获取你的参数名称长度
  let iStart = query.indexOf(param);// 获取你该参数名称的其实索引
  // eslint-disable-next-line eqeqeq
  if (iStart === -1)// -1为没有该参数
  { return '' }
  iStart += iLen + 1;
  let iEnd = query.indexOf('&', iStart);// 获取第二个参数的其实索引
  if (iEnd === -1)// 只有一个参数
  { return query.substring(iStart) }// 获取单个参数的参数值
  return query.substring(iStart, iEnd);// 获取第二个参数的值
};
/***
 * /生成动态不重复的一个16位的唯一标识
 */
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0;
    let v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }).toUpperCase();
};
/**
 * 文件大小格式化
 */
export const formatSize = (size) => {
  const unitsHash = 'B,KB,MB,GB'.split(',');
  let index = 0;
  // eslint-disable-next-line no-undef
  while (size > 1024 && index < unitsHash.length) {
    // eslint-disable-next-line no-param-reassign
    size /= 1024;
    index++;
  }
  // eslint-disable-next-line no-undef
  return Math.round(size * 100) / 100 + unitsHash[index];
};
