// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import config from '@/common/config.js';
import getBrowserDeviceInfo from '@/utils/getBrowseDeviceInfo';
import getUserAgent from '@/utils/getUserAgent';

const browserDeviceInfo = getBrowserDeviceInfo();
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  authDomain: `${config.firebaseProjectId}.firebaseapp.com`,
  databaseURL: `https://${config.firebaseProjectId}.firebaseio.com`,
  projectId: config.firebaseProjectId,
  storageBucket: `${config.firebaseProjectId}.appspot.com`,
  messagingSenderId: config.firebaseMessagingSenderId,
  appId: config.firebaseAppId,
  measurementId: config.firebaseMeasurementId,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
window.firebaseFn = function firebaseFn(eventName, eventParams = {}) {

  // 获取用户信息,判断是否登录授权
  const userBaseInfo = JSON.parse(window.localStorage.getItem('userBaseInfo')) || '';
  const getUserAgentFn = getUserAgent();

  const commonParam = {
    user_id: userBaseInfo && userBaseInfo.gwId || 'anonymous', // 用户账号 (用户登录的ID，如无登录则统计为匿名 anonymous)
    pc_os: browserDeviceInfo && browserDeviceInfo[0], // 操作系统
    browser_ver: browserDeviceInfo && browserDeviceInfo[0] && browserDeviceInfo[1] ? `${browserDeviceInfo[0]}_${browserDeviceInfo[1]}` : '', // 浏览器版本
    device_type: getUserAgentFn, // 设备类型( phone or pad or pc)
    device_resolution: `${window.screen.height}*${window.screen.width}`, // 屏幕分辨率
    browser_resolution: `${document.body.clientHeight}*${document.body.clientWidth}`, // 浏览器显示分辨率
    ctrl_name: `${eventParams.event_type === 'click' ? eventName : ''}`
  };

  firebase.analytics().logEvent(eventName, {
    ...commonParam,
    ...eventParams
  });
};