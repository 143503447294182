import * as type from '../type';

const defaultStates = {
  ua: '',
  isPc: 'pc',
  isPad: 'pad',
  isPhone: 'phone',
  userInfo: '',
  userBaseInfo: JSON.parse(window.localStorage.getItem('userBaseInfo')) || '',
  countryList: JSON.parse(window.localStorage.getItem('countryList')) || {},
  firebaseReducer: () => {}
};
export const app = (state = defaultStates, action = {}) => {
  switch (action.type) {
    case type.UA:
      return { ...state, ...action.payload };
    case type.USER_BASE_INFO:
      return {
        ...state,
        userBaseInfo: action.payload.userBaseInfo
      };
    // case type.COUNTRY_LIST:
    //   return {
    //     ...state,
    //     countryList: action.payload.countryList
    //   };
    default:
      return state;
  }
};
export const getStoreUseInfo = (state = defaultStates.userInfo, action = {}) => {
  switch (action.type) {
    case type.USER_INFO:
      return { ...action.payload };
    default:
      return state;
  }
};


export const firebaseReducer = (state = defaultStates.firebaseReducer, action = {}) => {
  switch (action.type) {
    case type.FIREBASE:
      return action.payload;
    default:
      return state;
  }
};
