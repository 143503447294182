import { BrowserRouter as Router } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './assets/common.scss';
import './assets/outer.scss';
import * as action from '@/store/app/action';
import _ from 'lodash';
import { routes } from './routers';
import RouterPrompt, { getRouteInfo } from './routers/RouterPrompt';
import renderRoutes from '@/utils/renderRoutes';
import getUrlParam from '@/utils/getUrlParam';
import { useTranslation } from 'react-i18next';
import PopUpLoading from '@/components/PopUpLoading';
import './locales/i18n';
import Header from './pages/Head/Head';
import '@/components/Firebase';
import Footer from './pages/Footer';
import BackTop from '@/components/BackTop';
import getUserAgent from '@/utils/getUserAgent';
import { getUserBaseInfo } from '@/api/user';

const App = () => {
  // 获取用户信息,判断是否登录授权
  // let { userBaseInfo } = useSelector((state) => ({
  //   userBaseInfo: state.app.userBaseInfo
  // }));
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const local = getUrlParam('local'); // 界面语言
  const incrementCounter = useCallback(() => dispatch(action.getUa(getUserAgent())), [dispatch]);
  useEffect(() => {
    incrementCounter();
    // 获取由官网跳转至此时url拼接的语言参数，动态改变全局i18n的语言
    if (local && local.toLowerCase() === 'en') {
      i18n.changeLanguage('en');
    } else if (_.isNil(local)) {
      // 判断local是否为空
      const pageLanguage = window.localStorage.getItem('i18nextLng');
      i18n.changeLanguage(pageLanguage || 'th');
    } else {
      i18n.changeLanguage('th');
    }
    // 网站初始化，调用需要token校验的接口，在request请求响应拦截中，对用户信息缓存进行清除
    const routeInfo = getRouteInfo(routes, window.location.pathname)
      ? getRouteInfo(routes, window.location.pathname)
      : routes.find((item) => {
        return item.path === '*';
      });
    if (routeInfo.requiresAuth) {
      getUserBaseInfo();
    }
  }, []);
  // 此处校验是否登录与调用需要校验token得api有冲突
  // 路由中需要校验token的页面requiresAuth本身就是true
  // 此处重定向会导致初期需要调校验token得api得页面会跳转两次登录页面
  // const authed = Boolean(userBaseInfo);

  return (
    <Router>
      <Header />
      <div className={`${getUserAgent()}-gwm-content`} id="gwmContentId">
        <RouterPrompt></RouterPrompt>
        <React.Suspense fallback={<PopUpLoading />}>{renderRoutes(routes, true, '/')}</React.Suspense>
      </div>
      <Footer />
      <BackTop duration={400} target={() => document.getElementById('gwm-container')} visibilityHeight={400}></BackTop>
    </Router>
  );
};

export default App;
