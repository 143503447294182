import React from 'react';
// 路由懒加载
const Login = React.lazy(() => import('@/pages/UserLogin'));
// 个人账户
const AccountInfo = React.lazy(() => import('@/pages/Account'));
const Profile = React.lazy(() => import('@/pages/Account/AccountInfo/Profile'));
const AccountSetting = React.lazy(() => import('@/pages/Account/AccountInfo/AccountSetting'));
const ChangePassword = React.lazy(() => import('@/pages/Account/AccountInfo/ChangePassword'));
// const PrivacyAndSecurity = React.lazy(() => import('@/pages/Account/AccountInfo/PrivacyAndSecurity'));
// 消息中心
const Notification = React.lazy(() => import('@/pages/Account/Notification'));
// 整车订单列表页
const MyOrder = React.lazy(() => import('@/pages/Account/MyOrder'));
// 预约试驾列表页
const TestDriveList = React.lazy(() => import('@/pages/Account/TestDriveList'));
// 旧订购页面
const OrderVehicles = React.lazy(() => import('@/pages/OrderVehicles'));
const NormalOrderVehicles = React.lazy(() => import('@/pages/OrderVehicles/Normal'));
const PreSaleOrderVehicles = React.lazy(() => import('@/pages/OrderVehicles/PreSale'));
const VipBuyOrderVehicles = React.lazy(() => import('@/pages/OrderVehicles/VipBuy'));
// 车系列表页
const SeriesList = React.lazy(() => import('@/pages/OrderVehicles/SeriesList'));
// 新订购页面
// const NewOrderVehicles = React.lazy(() => import('@/pages/NewOrderVehicles'));
// 订单确认页
const Payment = React.lazy(() => import('@/pages/Payment/index'));
// const OrderConfirm = React.lazy(() => import('@/pages/OrderConfirm/index'));
// 整车订单详情
const OrderDetail = React.lazy(() => import('@/pages/Account/MyOrderDetail'));
// 预约试驾订单详情
const TestDriveDetail = React.lazy(() => import('@/pages/Account/TestDriveList/orderDetail'));
// 预约试驾
const TestDrive = React.lazy(() => import('@/pages/TestDrive'));
// 预约试驾门店查询
const StoreTestDrive = React.lazy(() => import('@/pages/TestDrive/StoreTestDrive'));
// 订单成功页
const OrderSuccess = React.lazy(() => import('@/pages/OrderSuccess'));
// 订单详情
const TestDriveOrder = React.lazy(() => import('@/pages/TestDrive/TestDriveOrder'));
const LoginOut = React.lazy(() => import('@/pages/LoginOut/LoginOut'));
const Waiting = React.lazy(() => import('@/pages/waiting'));
// 金融计算器
const FinancialCalculator = React.lazy(() => import('@/pages/financialCalculator/financialCalculator.jsx'));
const NotFound = React.lazy(() => import('@/pages/NotFound'));
// 评价
const Comment = React.lazy(() => import('@/pages/Comment'));
const CommentSuccess = React.lazy(() => import('@/pages/Comment/CommentSuccess'));
// 云展厅
const CloudShowroom = React.lazy(() => import('@/pages/CloudShowroom'));
const CloudShowroomGuide = React.lazy(() => import('@/pages/CloudShowroom/Guide'));
const CloudShowroomHall = React.lazy(() => import('@/pages/CloudShowroom/Hall'));
const CloudShowroomInterior = React.lazy(() => import('@/pages/CloudShowroom/Interior'));
const CloudShowroomOutward = React.lazy(() => import('@/pages/CloudShowroom/Outward'));
const CloudShowroomOutwardTest = React.lazy(() => import('@/pages/CloudShowroom/OutwardTest'));
// 里程计算器
// const MileageCalculator = React.lazy(() => import('@/pages/MileageCalculator'));

// 测试
// import ProgressBar from '@/components/ProgressBar';
const ProgressBar = React.lazy(() => import('@/components/ProgressBar'));

const Encode = React.lazy(() => import('@/pages/Encode'));

const UnderMaintenance = React.lazy(() => import('@/pages/UnderMaintenance'));

export const routes = [
  {
    path: '/',
    exact: true,
    component: Login,
    requiresAuth: false
  },
  {
    path: '/login',
    component: Login,
    requiresAuth: false
  },
  {
    path: '/ProgressBar',
    component: ProgressBar,
    requiresAuth: false
  },
  {
    path: '/orderVehicles',
    component: OrderVehicles,
    requiresAuth: false,
    routes: [
      {
        path: '/orderVehicles',
        component: OrderVehicles,
        requiresAuth: false,
        exact: true
      },
      // TODO：常规订单（暂定除vipBuy以外所有购车走normal，后期改造）
      {
        path: '/orderVehicles/normal',
        component: NormalOrderVehicles,
        requiresAuth: false
      },
      // 预售订单
      {
        path: '/orderVehicles/preSale',
        component: PreSaleOrderVehicles,
        requiresAuth: false
      },
      // VIP购车
      {
        path: '/orderVehicles/vipBuy',
        component: VipBuyOrderVehicles,
        requiresAuth: true
      }
    ]
  }, // 订购(车型信息选择页)
  {
    path: '/seriesList',
    component: SeriesList,
    requiresAuth: true
  }, // 车系列表页
  {
    path: '/payment',
    component: Payment,
    requiresAuth: false
  }, // 订购（订单确认页）
  // {
  //   path: '/newOrderVehicles',
  //   component: NewOrderVehicles,
  //   requiresAuth: false,
  // }, // New一个订购, test
  {
    path: '/orderConfirm/:orderNo',
    component: Payment,
    requiresAuth: true
  }, // 预售转常规后的订单修改页
  // 个人中心
  {
    path: '/accountInfo',
    component: AccountInfo,
    requiresAuth: true,
    routes: [
      {
        path: '/accountInfo',
        component: Profile,
        requiresAuth: true,
        exact: true
      }, // 账户信息
      {
        path: '/accountInfo/accountSettings',
        component: AccountSetting,
        requiresAuth: true
      }, // 账户信息设置
      {
        path: '/accountInfo/changePassword',
        component: ChangePassword,
        requiresAuth: true
      }, // 密码修改
      // { path: '/accountInfo/privacyAndSecurity', component: PrivacyAndSecurity, requiresAuth: true }, // 隐私协议&用户政策
      {
        path: '/accountInfo/notifications',
        component: Notification,
        requiresAuth: true
      }, // 消息列表
      {
        path: '/accountInfo/myOrder',
        component: MyOrder,
        requiresAuth: true
      }, // 订单列表
      {
        path: '/accountInfo/testDriveList',
        component: TestDriveList,
        requiresAuth: true
      } // 预约试驾列表
    ]
  },
  {
    path: '/orderDetail/:no',
    component: OrderDetail,
    requiresAuth: true
  }, // 订单详情
  {
    path: '/testDriveDetail/:no',
    component: TestDriveDetail,
    requiresAuth: true
  }, // 预约试驾详情页
  {
    path: '/testDrive',
    component: TestDrive,
    requiresAuth: false
  }, // 预约试驾
  {
    path: '/loginOut',
    component: LoginOut,
    requiresAuth: true
  },
  {
    path: '/gwm',
    component: Waiting,
    requiresAuth: false
  },
  {
    path: '/storeTestDrive',
    component: StoreTestDrive,
    requiresAuth: false
  }, // 预约试驾门店查询
  {
    path: '/orderSuccess/:orderNo',
    component: OrderSuccess,
    requiresAuth: false
  }, // 订购成功页
  {
    path: '/testDriveOrder',
    component: TestDriveOrder,
    requiresAuth: false
  }, // 预约试驾成功页
  {
    path: '/financialCalculator',
    component: FinancialCalculator,
    requiresAuth: false
  }, // 金融计算器
  {
    path: '/comment',
    component: Comment,
    requiresAuth: true
  }, // 评价
  {
    path: '/commentSuccess',
    component: CommentSuccess,
    requiresAuth: true
  }, // 评价成功
  {
    path: '/cloudShowroom',
    component: CloudShowroom,
    requiresAuth: false,
    routes: [
      {
        path: '/cloudShowroom',
        component: CloudShowroomGuide,
        requiresAuth: false,
        exact: true
      }, // 展厅选择
      {
        path: '/cloudShowroom/hall',
        component: CloudShowroomHall,
        requiresAuth: false
      }, // 3d展厅大厅
      {
        path: '/cloudShowroom/interior',
        component: CloudShowroomInterior,
        requiresAuth: false
      }, // 3d展厅车辆内饰
      {
        path: '/cloudShowroom/outward',
        component: CloudShowroomOutward,
        requiresAuth: false
      }, // 3d展厅车辆外饰
      {
        path: '/cloudShowroom/outwardTest',
        component: CloudShowroomOutwardTest,
        requiresAuth: false
      } // 3d展厅车辆外饰
    ]
  },
  // {
  //   path: '/mileageCalculator',
  //   component: MileageCalculator,
  //   requiresAuth: false
  // }, // 评价成功

  // 挡板页面
  {
    path: '/underMaintenance',
    component: UnderMaintenance,
    requiresAuth: false
  },
  {
    path: '/encode',
    component: Encode,
    requiresAuth: false
  }, // 评价成功
  {
    path: '*',
    component: NotFound,
    requiresAuth: false
  }
];
