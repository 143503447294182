import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import en_US from './en_US.json';
import th_TH from './th_TH.json';
import zh_CN from './zh-CN.json';
import getUrlParam from '../utils/getUrlParam';
import {
  initReactI18next
} from 'react-i18next';
// i18nextLng 为i18n 自带的储存的参数
const localLang = window.localStorage.getItem('i18nextLng');
let urlLocale = getUrlParam('locale');
const carBrand = getUrlParam('carBrand');
let currentLang = urlLocale
  ? urlLocale
  : localLang
    ? localLang
    : 'th';
// let href = window.location;
// if (urlLocale === '') {
//   currentLang = 'th';
// }
// if (urlLocale || urlLocale === '') {
//   window.history.replaceState(null, '', `${href.pathname}${carBrand ? '?carBrand=' + carBrand : ''}`); // 因为订购页面切换语言需要刷新页面，如果带语言参数，会一直走参数语言，造成刷新后，语言不是用户所选择的
// }
// 如果当前的lang和localstorage不同，则重新替换，优先以url中的locale为主

if (currentLang !== localLang) {
  window.localStorage.setItem('i18nextLng', currentLang);
}
if (currentLang === 'th') {
  document.getElementById('gwm-container').className = 'thi-container';
}
i18n.use(LanguageDetector) // 嗅探当前浏览器语言
  .use(initReactI18next) // init i18next
  .init({
    // 引入资源文件
    resources: {
      en: {
        translation: en_US,
        // antTranslation: enus,
      },
      th: {
        translation: th_TH,
        // antTranslation: thth
      },
      cn: {
        translation: zh_CN,
        // antTranslation: zhcn
      },
    },
    // 选择默认语言，选择内容为上述配置中的key，即en/th
    fallbackLng: currentLang,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
export default i18n;
