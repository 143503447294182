/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable space-before-function-paren */
import axios from 'axios';
import qs from 'qs';
import { message, Modal } from 'antd';
import i18n from 'i18next';
import '../pages/UserLogin/qulityLogin.scss';
import { logout } from '@/api/user';
import { countryShortcut } from '@/common/config';

let already607501; // code 607501 防抖
if (axios && axios.isCancel()) {
  console.log('isCancel');
}
// 创建axios实例
let instance = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'If-Modified-Since': ['0']
  },
  timeout: 60000
});

const createStoreFun = () => {
  let ua;
  const gwmClient = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(gwmClient);
  const isSymbian = /(?:SymbianOS)/.test(gwmClient) || isWindowsPhone;
  const isAndroid = /(Android);?[\s\/]+([\d.]+)?/.test(gwmClient);
  const isFireFox = /(?:Firefox)/.test(gwmClient);
  const isTablet = /(?:iPad|PlayBook)/.test(gwmClient) || (isAndroid && !/(?:Mobile)/.test(gwmClient)) || (isFireFox && /(?:Tablet)/.test(gwmClient));
  const isPhone = /(?:iPhone)/.test(gwmClient) && !isTablet;
  const isPc = !isPhone && !isAndroid && !isSymbian;

  if (isAndroid || isPhone) {
    ua = 'phone';
  } else if (isTablet) {
    ua = 'pad';
  } else if (isPc) {
    ua = 'pc';
  }
  return ua;
};
const getSystemType = () => {
  // 未知（其他）：0，Android:1,IOS:2，WIN 3 ，MAC 4
  const agent = navigator.userAgent.toLowerCase();
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const MAC = /macintosh|mac os x/i.test(navigator.userAgent) && !window.MSStream;
  const android = /Android/.test(navigator.userAgent) && !window.MSStream;
  if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
    return 3; // 此处根据需求调整
  }
  if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
    return 3; // 此处根据需求调整
  }
  if (iOS) {
    return 2;
  }
  if (MAC) {
    return 4;
  }
  if (android) {
    return 1;
  }
  return 0;
};

// 反向代理
export const gateway = '/pc-apis/';
// 封装常用请求方法
const requestMethos = {
  get(url, data, options) {
    if (!options) {
      options = {};
    }
    options.params = data || {};
    // 多层嵌套参数对象序列化
    options.paramsSerializer = (params) => {
      return qs.stringify(params, {
        indices: false
      });
    };
    return instance.get(url, options);
  },
  post(url, data, options) {
    return instance.post(url, data || {}, options);
  },
  put(url, data, options) {
    return instance.put(url, data || {}, options);
  },
  delete(url, data, options) {
    if (!options) {
      options = {};
    }
    options.data = data || {};
    return instance.delete(url, options);
  }
};
// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    let loginData = JSON.parse(localStorage.getItem('loginData'));
    let i18nextLng = localStorage.getItem('i18nextLng');
    const time = new Date();
    // 请求发送前进行处理
    config.headers['beanId'] = (loginData && loginData.beanId) || '';
    config.headers['brand'] = '1';
    config.headers['enterPriseId'] = 'CC001';
    config.headers['rs'] = '1'; // 2 是app 1 是pc
    config.headers['terminal'] = 'GW_APP_Haval';
    config.headers['channel'] = 'APP';
    config.headers['accessToken'] = (loginData && loginData.accessToken) || '';
    config.headers['regionCode'] = 'CN';
    config.headers['systemType'] = 1;
    config.headers['appId'] = '1';
    config.headers['language'] = i18nextLng || 'en';
    config.headers['country'] = countryShortcut;
    // 评价中心追加-header参数
    config.headers['countryCode'] = countryShortcut;
    config.headers['requestNo'] = 'N' + time.valueOf() + Math.floor(Math.random() * 10);
    config.headers['operator'] = loginData?.gwId || '';
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.code === '000000') {
      return response.data;
    } else {
      // 返回状态为200但服务器报错时提示错误信息
      const { description, code } = response.data;
      if (code === '551004' || code === '308002' || code === '551011' || code === '550004') {
        // 查找不需要授权的路由，对缓存的用户信息进行清除
        // let noRequireAuthRoutes = [];
        // routes.forEach(item => {
        //   if (!item.requiresAuth) {
        //     // 排除路由中此情况path: '/orderConfirm/:orderNo'
        //     let _path = item.path.split('/:')?.[0];
        //     noRequireAuthRoutes.push(_path);
        //   }
        // });
        // // console.log('noRequireAuthRoutes', noRequireAuthRoutes);
        // let noRequireAuth;
        // noRequireAuthRoutes.forEach(item => {
        //   if (window.location.pathname?.startsWith(item)) {
        //     noRequireAuth = true;
        //   }
        // });
        // 551004：accessToken为空、登录Token已经失效
        // 308002：token过期
        // 551011：refreshToken已经失效
        // 550004：token已经失效
        window.localStorage.removeItem('loginData');
        window.localStorage.removeItem('userBaseInfo');
        window.location.href = `/login?from=${window.location.pathname + window.location.search}`;
      } else if (code === '607501') {
        // 607501：您的账号在其他设备登录，请注意账号安全。 账号在其他设备登录，需要重新登录
        if (already607501) {
          return Promise.reject(response.data);
        } else {
          already607501 = true;
          Modal.confirm({
            content: `${i18n.t('LOGGED_ANOTHER_DEVICE')}`,
            okText: `${i18n.t('OK')}`,
            icon: '',
            className: `logged_not ${createStoreFun()}_logged_note`,
            destroyOnClose: true,
            centered: true,
            mask: true,
            getContainer: () => document.getElementById('gwm-container'),
            confirmLoading: already607501,
            onOk: () => {
              return new Promise((resolve, reject) => {
                already607501 = false;
                logout({})
                  .then((res) => {
                    if (res.code === '000000') {
                      window.localStorage.removeItem('loginData');
                      window.localStorage.removeItem('userBaseInfo');
                      window.location.href = `/login?from=${window.location.pathname + window.location.search}`;
                      resolve();
                    }
                  })
                  .catch();
                // eslint-disable-next-line no-console
              }).catch(() => console.log('Oops errors!'));
            }
          });
          return Promise.reject(response.data);
        }
      } else if (code === '110641' || code === '308097' || code === '308049' || code === 'Retain_ERROR-510001' || code === 'VO-000054') {
        // 不需要处理的code
        // 'VO-000054': 订单不支持修改
        return Promise.reject(response.data);
      } else if (code === '308022' || code === 'VO-000008' || code === 'PAYMENT-800016') {
        // 手机号码重复（Mobile number exists）或该订单已支付
        return Promise.resolve(response.data);
      } else if (code === '001') {
        return Promise.resolve(response.data);
      } else if (code === 'VO-000033') {
        // 支付订单无库存
        return Promise.resolve(response.data);
      } else if (code === '900005') {
        // 支付订单无库存
        return Promise.resolve(response.data);
      } else if (code === '110004') {
        return Promise.resolve(response.data);
      } else if (code === '110003') {
        return Promise.resolve(response.data);
      } else if (code === '110002') {
        return Promise.resolve(response.data);
      } else if (code === '110001') {
        return Promise.resolve(response.data);
      } else if (code === 'MARKET_ORDER-000010') {
        return Promise.resolve(response.data);
      } else if (code === 'EXCLUSIVE_PURCHASE-000001' || code === 'EXCLUSIVE_PURCHASE-000002' || code === 'EXCLUSIVE_PURCHASE-000003') {
        // VIP购车错误码
        return Promise.reject(response.data);
      } else if (code === '607407') {
        // 挡板页面
        window.location.href = '/underMaintenance';
      } else {
        message.error({
          content: description ? description : `${i18n.t('SERVER_ERROR')}`, // 一般不会为空，订单详情页 开发项目运行，会出现为空的情况
          className: `${createStoreFun()}Message_css`
        });
      }
      if (code === '600020') {
        // code待确定, 返回login页
      }
      return Promise.reject(response.data);
    }
  },
  (error) => {
    // 返回错误状态码处理
    if (error.response) {
      const { data } = error.response;
      message.error({
        content: data.description || data,
        className: `${createStoreFun()}Message_css`
      });
    } else {
      message.error({
        content: `${i18n.t('NETWORK_EXCEPTION')}`,
        className: `${createStoreFun()}Message_css`
      });
    }
    return Promise.reject(error);
  }
);

export default requestMethos;
