/***
 * loading 组件
 *
 * isModalVisible true代表显示 false 代表隐藏
 *
 * lihongtao
 * 2021.4.29
 *
 */

import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.scss';
import img from '@/assets/__img__/login/loading.gif';
const PopUpLoading = (props) => {
  const { isModalVisible, showPage } = props;
  let { t } = useTranslation();
  // 判断是pc 还是移动端
  const { app } = useSelector((state) => ({
    app: state.app,
  }));
  return (
    <div>
      {
        !showPage ? <Modal
          visible={isModalVisible}
          centered={true}
          closable={false}
          footer={null}
          maskClosable={true}
          width={app.ua === app.isPhone ? (!showPage ? '15rem' : 0) : (!showPage ? '20rem' : 0)}
        >
          <div
            className={
              app.ua === app.isPhone
                ? 'phoneVisible_loading'
                : 'pcVisible_loading'
            }
          >
            <div>
              <img src={img} draggable="false" />
            </div>
            <p>{t('LOADING')}</p>
          </div>
        </Modal> : <Modal
          visible={isModalVisible}
          centered={true}
          closable={false}
          footer={null}
          maskClosable={true}
          mask={false}
          // wrapClassName="abs"
          // maskStyle={{ background: '', opacity: '0.1' }}
          width={0}
        >
          <div
            className={
              app.ua === app.isPhone
                ? 'phoneVisible_loading_'
                : 'pcVisible_loading_'
            }
          >
            <div>
              <img src={img} draggable="false" />
            </div>

          </div>
        </Modal>
      }

    </div>

  );
};
PopUpLoading.prototype = {
  isModalVisible: PropTypes.bool,
};
PopUpLoading.defaultProps = {
  isModalVisible: false
};
export default PopUpLoading;
