import React from 'react';
import PropTypes from 'prop-types';
import { BackTop } from 'antd';
import { useSelector } from 'react-redux';
import iconBackTop from '@/assets/__img__/accountInfo/icon-back-top.png';
import './index.scss';
const Index = (props) => {
  const { app } = useSelector((state) => ({
    app: state.app
  }));
  const { duration, target, visibilityHeight = 400, onClick } = props || {};
  return (
    <div className={`gwm-back-top gwm-${app.ua}-back-top`}>
      <BackTop
        duration={duration}
        target={target}
        visibilityHeight={visibilityHeight}
        onClick={onClick}
      >
        <img className="gwm-back-top-icon" src={iconBackTop}></img>
      </BackTop>
    </div>
  );
};
Index.propTypes = {
  duration: PropTypes.number,
  target: PropTypes.func,
  visibilityHeight: PropTypes.number,
  onClick: PropTypes.func,
};
Index.defaultProps = {
  duration: 450,
  target: () => { return false },
  visibilityHeight: 400,
  onClick: () => { return false },
};
export default Index;