// 标识
export const UA = 'UA';
// 车型内容
export const CAR_MODEL = 'CAR_MODEL';
// 外饰
export const EXTERIOR = 'EXTERIOR';
// 内饰
export const INTERIOR = 'INTERIOR';
// 商品
export const SPU = 'SPU';
// 体验中心
export const EXPER_CENTER = 'EXPER_CENTER';
// 提车地址
export const SET_PICK_CAR_INFO = 'SET_PICK_CAR_INFO';
// 提车人信息
export const SET_PICK_CAR_USER_INFO = 'SET_PICK_CAR_USER_INFO';
// 用户信息
export const USER_INFO = 'USER_INFO';
// 登录用户信息
export const LOGIN_USER = 'LOGIN_USER';
// 用户信息
export const USER_BASE_INFO = 'USER_BASE_INFO';
// 互斥
export const Mutex = 'Mutex';
// 外饰状态
export const EXTERIOR_STATUS = 'EXTERIOR_STATUS';
// 修改手机号的验证码
export const SMSCODE = 'SMSCODE';
// 修改订购手机号
export const PURCHASE_PHONE = 'PURCHASE_PHONE';
// 规格参数
export const SPECS_STATUS = 'SPECS_STATUS';
// 金融计算器计算结果
export const FINANCIAL_DATA = 'FINANCIAL_DATA';
// 车型价格
export const SELLINGPRICE_DATA = 'SELLINGPRICE_DATA';
// 订购各参数组件
export const ORDER_PARAMS_TYPE = 'ORDER_PARAMS_TYPE';
// 选择车型结果
export const ALLCAR_DATA = 'ALLCAR_DATA';
// 金融计算器轮播图
export const BANNER_LIST = 'BANNER_LIST';
// 金融计算器选择结果
export const SELECT_RESULTS = 'SELECT_RESULTS';
// 查询车辆详细信息
export const CAR_DETAIL = 'CAR_DETAIL';
// 金融计算器 总售价
export const SELLING_PRICE = 'SELLING_PRICE';
// 金融计算器 政府补贴价
export const SUBSIDY_PRICE = 'SUBSIDY_PRICE';
// 金融计算器 优惠价
export const SPECIAL_PRICE = 'SPECIAL_PRICE';
// 金融信息描述
export const FINANCAL_DESCRIPTION = 'FINANCAL_DESCRIPTION';
// 订购页面顶部tab导航按钮方法
export const VEHICLE_TAB_FN = 'VEHICLE_TAB_FN';
// 订购页面顶部tab index
export const VEHICLE_TAB_INDEX = 'VEHICLE_TAB_INDEX';
// 订购页面顶部tab是否可点击setVehicleTabVisible
export const VEHICLE_TAB_VISILBLE = 'VEHICLE_TAB_VISILBLE';
// 订购页面顶部tab是否隐藏
export const VEHICLE_TAB_DISPLAY = 'VEHICLE_TAB_DISPLAY';
// 埋点 firebase
export const FIREBASE = 'FIREBASE';

// 冻结头部tabs状态
export const VEHICLE_TABS_FROZEN = 'VEHICLE_TABS_FROZEN';
// 秒杀文字
export const LABEL_CONTENT = 'LABEL_CONTENT';
// 秒杀倒计时
export const COUNT_DOWN = 'COUNT_DOWN';
// 秒杀 限购数量
export const PURCHASE_LIMITATION = 'PURCHASE_LIMITATION';
// 是不是秒杀
export const SECKILL_TYPE = 'SECKILL_TYPE';
// 秒杀商品
export const SKUSTOCK_LIST = 'SKUSTOCK_LIST';
// 秒杀商品 取消时间
export const MARKET_INFODTO = 'MARKET_INFODTO';