/***
 *
 * 快速登录 电话号码登录组件
 */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@/api/user';
import { useTranslation } from 'react-i18next';
import getUrlParam from '@/utils/getUrlParam';
import './Head.scss';
import Loading from '@/components/PopUpLoading';
import PromptBox from '@/components/PromptBox';
import Drawer from './components/Drawer';
import _ from 'lodash';
import SwitchLang from './components/switchLang';
import config from '@/common/config.js';
import { setStoreUserInfo, setUserBaseInfo } from '../../store/app/action';
import { setVehicleTabIndex } from '@/store/orderVehicle/action';
import unLoggedAva from '@/assets/__img__/header/unlogged_default_avatar.png';
import newLogo from '@/assets/__img__/header/new_logo_2022.png';
import { matchUrlImg } from '@/utils/index';

const logo = matchUrlImg(`header/gwm_logo.png`);
// const newLogo = matchUrlImg('header/new_logo_2022.png');
const unnamedAva = matchUrlImg(`header/unnamed.png`);
const toggleIco = matchUrlImg(`header/toggle.png`);
const closeBtnIco = matchUrlImg(`header/close_btb.png`);

const vehicleMenu = [
  'VERSION',
  'EXTERIOR',
  'INTERIOR',
  // 'CHECKOUT'
];
// 有tabs页头的head
const routeHasTabs = ['/ordervehicles/normal', '/ordervehicles/vipbuy', '/newordervehicles'];

const noAccess = ['/login', '/', '/testdrive', '/storetestdrive', '/ordervehicles', '/testdriveorder', '/loginout', '/financialcalculator'];
const needAccess = ['/accountinfo', '/gwm'];

const Head = (props) => {

  let { t, i18n } = useTranslation();
  const childRef = useRef();
  const { app, userBaseInfo, vehicleTabVisible, vehicleTabsFrozen, vehicleTabDisplay } = useSelector((state) => {
    return ({
      app: state.app, // ua = "phone" "pad";ua = "pc";
      userBaseInfo: state.app.userBaseInfo, // 获取登陆时保存的用户信息
      vehicleTabVisible: state.vehicleTabVisible, // title是否可点击
      vehicleTabsFrozen: state.vehicleTabsFrozen,
      vehicleTabDisplay: state.vehicleTabDisplay
    });
  });
  const locale = window.localStorage.getItem('i18nextLng');
  // logo回跳官网的当前语言所对应链接
  let officialWebsite = config.officialDomain;
  if (locale === 'th') {
    officialWebsite = config.officialDomain;
  }

  const isPayment = getUrlParam('isPayment'); // 是否为订单确认页

  // 语言切换按钮list
  const [logStatus, setLogStatus] = useState(false);// 当前登陆状态 true登录，false未登录
  const [loading, setLoading] = useState(false); // 菜单打开状态

  // 语言切换功能
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  // 退出登录
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showLogoutConfirm = () => {
    if (logStatus) {
      if (app.ua === 'phone') {
        childOnClose();
      }
      setShowConfirmModel(true);
    } else {
      history.push(`/login?from=${window.location.pathname + window.location.search}`);
      childOnClose();
    }
  };
  const childOnClose = () => {
    if (app.ua === 'phone') {
      childRef.current.childOnClose(); // 此处调用子组件方法 childOnClose
    }
  };
  const logInOutFn = () => {
    if (logStatus) {
      setConfirmLoading(true);
      logout({}).then((res) => {
        if (res.code === '000000') {
          window.localStorage.removeItem('loginData');
          window.localStorage.removeItem('userBaseInfo');
          setShowConfirmModel(false);
          setConfirmLoading(false);
          dispatch(setUserBaseInfo(''));
          history.push(`/login?from=${window.location.pathname + window.location.search}`);
          childOnClose();
        }
      }).catch((res) => {
        setConfirmLoading(false);
        setShowConfirmModel(false);
      });
    } else {
      history.push(`/login?from=${window.location.pathname + window.location.search}`);
      childOnClose();
    }
  };
  // 检测登陆状态
  const checkLogStatus = () => {
    if ((!noAccess.includes(location.pathname.toLowerCase()) && location.pathname.toLowerCase().indexOf('/testdrivedetail/') > -1) || needAccess.includes(location.pathname.toLowerCase())) {
      setLoading(true);
      dispatch(setStoreUserInfo((code) => {
        if (code === '000000') {
          setLoading(false);
          setLogStatus(true);
        } else {
          setLogStatus(false);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      );
    } else {
      setLoading(false);
    }
  };

  // 去个人中心
  const goToAccount = () => {
    if (location.pathname.toLowerCase() === '/accountinfo') {
      return;
    } else {
      history.push('/accountInfo');
      childOnClose();
    }
  };
  // 根据路由变化,动态检测登陆状态
  useEffect(() => {
    // 判断token是否失效
    checkLogStatus();
  }, [location.pathname]);

  // 如果在订购页面登录 需要检测并同步登录状态
  useEffect(() => {
    if (userBaseInfo) {
      setLoading(false);
      setLogStatus(true);
    } else {
      setLogStatus(false);
    }
  }, [userBaseInfo]);

  // 根据语言动态切换,改变logo跳转链接
  useEffect(() => {
    if (i18n.language === 'th') {
      officialWebsite = config.officialDomain;
    } else if (i18n.language === 'en') {
      officialWebsite = config.officialDomain;
    }
  }, [i18n.language]);

  // 读取订购页面，tab点击事件
  // if()
  const { vehicleTabFn, vehicleTabIndex } = useSelector((state) => ({
    vehicleTabFn: state.vehicleTabFn,
    vehicleTabIndex: state.vehicleTabIndex,
  }));
  const [vehicleTabInx, setVehicleTabInx] = useState(1);

  const vehicleTabChange = (key) => {
    vehicleTabFn(key);
    setVehicleTabInx(Number(key));
    dispatch(setVehicleTabIndex(Number(key)));
  };

  useEffect(() => {
    if (vehicleTabFn) {
      vehicleTabChange('1');
    }
  }, [vehicleTabFn]);
  useEffect(() => {
    if (vehicleTabIndex) {
      vehicleTabChange(vehicleTabIndex.toString());
      dispatch(setVehicleTabIndex(vehicleTabIndex));
    }
  }, [vehicleTabIndex]);

  const handleVehicleTab = (index) => {
    // tab状态冻结
    if (vehicleTabsFrozen) {
      return;
    }
    if (
      !(((index + 1) === vehicleTabIndex) ||
       (location.pathname.toLowerCase() === '/newordervehicles' && !vehicleTabVisible))
    ) {
      vehicleTabChange(`${index + 1}`);
    }
  };
  const pcHeader = () => {
    return (
      <div className={`nav ${app.ua}_nav`}>
        {/* 头部 */}
        <div className={`nav_left ${app.ua}_nav_left`}>
          <a href={officialWebsite}>
            <img className="logoImg" src={newLogo} alt="Logo" draggable="false" />
          </a>
        </div>
        {
          app.ua === app.isPc &&
           _.includes(routeHasTabs, location.pathname.toLowerCase()) &&
            vehicleTabFn &&
            !isPayment ? (
              <div className="nav_center" style={{ visibility: vehicleTabDisplay ? 'visible' : 'hidden' }}>
                {
                  _.map(vehicleMenu, (item, index) => (
                    <div
                      key={index + 1}
                      className={`vehicle-tabs ${index + 1 === vehicleTabInx ? 'vehicle-tab-active' : ''} ${location.pathname.toLowerCase() === '/newordervehicles' && !vehicleTabVisible ? 'vehicle-tabs-can-not-be-click' : ''} ${index !== 0 && vehicleTabsFrozen ? 'vehicle-tabs-disable' : ''}`}
                      onClick={() => { handleVehicleTab(index) }}
                    >
                      {t(item)}
                    </div>
                  ))
                }
              </div>
            ) : null
        }
        <div className="nav_right">
          <SwitchLang />
          {
            (location.pathname !== '/' && location.pathname !== '/login') ? (
              <div className="user_info clearfix">
                <div className="log_out">
                  <span onClick={() => showLogoutConfirm()}>【{logStatus ? t('LOGOUT') : t('SIGN_UP_LOGIN')}】</span>
                </div>
                <PromptBox
                  visible={showConfirmModel}
                  setModalVisible={(code) => setShowConfirmModel(code)}
                  onOkCalback={() => logInOutFn()}
                  btnOKTxt={t('CONFIRM')}
                  btnCancelTxt={t('CANCEL')}
                  tips={t('SURE_TO_QUIT')}
                  loading={confirmLoading}
                />
                <div className={`${userBaseInfo ? '' : 'not_login'} user_avator_name`}>
                  <span onClick={() => goToAccount()}>
                    <span className="avator">
                      <img src={userBaseInfo.avatar || (logStatus ? unnamedAva : unLoggedAva)} alt="" draggable="false" />
                    </span>
                    <span
                      className="name_show">{userBaseInfo.nick || (logStatus ? t('UNNAMED') : '')}</span>
                  </span>
                </div>

              </div>
            ) : null
          }
        </div>
      </div>
    );
  };
  const mobildHeader = () => {
    return (
      <div className={`nav ${app.ua}_nav`} id={`${app.ua}_nav`}>
        {/* 头部 */}
        <div className={`nav_left ${app.ua}_nav_left`}>
          <a href={officialWebsite}>
            <img className="logoImg" src={newLogo} alt="Logo" draggable="false" />
          </a>
        </div>
        {
          (location.pathname !== '/' && location.pathname !== '/login') ? (
            <div className="nav_right">
              <div
                className="dropdown_menu"
                onClick={() => childRef.current.childShowDrawer()}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={e => e.preventDefault()}
                >
                  <img src={toggleIco} alt="=" draggable="false" />
                </a>
              </div>
            </div>
          ) : null
        }
        <div
          className={`nav_center ${(location.pathname !== '/' && location.pathname !== '/login') ? '' : 'login_nav_center'}`}
        >
          <SwitchLang />
        </div>
        <PromptBox
          visible={showConfirmModel}
          setModalVisible={(code) => setShowConfirmModel(code)}
          onOkCalback={() => logInOutFn()}
          btnOKTxt={t('CONFIRM')}
          btnCancelTxt={t('CANCEL')}
          tips={t('SURE_TO_QUIT')}
          loading={confirmLoading}
        />
        {/* 下拉菜单 */}
        <Drawer
          ref={childRef}
          officialWebsite={officialWebsite}
          logStatus={logStatus}
          showLogoutConfirm={() => showLogoutConfirm()}
        />
      </div>
    );
  };
  return (
    <Fragment>
      <Loading isModalVisible={loading} />
      {app.ua !== 'phone' && pcHeader()}
      {app.ua === 'phone' && mobildHeader()}
    </Fragment>
  );
};

export default Head;
