const countryShortcut = 'TH'; // 接口header 中的country 参数
let officialDomain;
let thirdPartyDomain;
let storeInfo;
let YMChatBotID;
let firebaseApiKey;
let firebaseProjectId;
let firebaseProjectNumber;
let firebaseMessagingSenderId;
let firebaseAppId;
let firebaseMeasurementId;
officialDomain = 'https://www.gwm.co.th';
if (window.location.href.indexOf('ap2-gwm-e-commerce-test') > -1 || window.location.href.indexOf('localhost') > -1) {
  thirdPartyDomain = 'https://ap2-gwm-e-commerce-test.gwmcloud.com';
  storeInfo = {
    testDriveStoreId: '100387', // 体验店
    testDriveStore: '用于整车订单关联dms100002的门店',
    deliveryStoreId: '100368', // 服务店
    deliveryStore: '用于整车订单关联dms100002的门店2体验店'
  }; // 测试store信息

  YMChatBotID = 'x1616165706679';
  firebaseApiKey = 'AIzaSyBaZeHT8qTxxsI_1aDPM20qdWOabpOc8qw';
  firebaseProjectId = 'gwm-test';
  firebaseProjectNumber = '996210922725';
  firebaseMessagingSenderId = '996210922725';
  firebaseAppId = '1:996210922725:web:5ab21838339802935db00c';
  firebaseMeasurementId = 'G-M3Y2PJ9R1Y';

} else if (window.location.href.indexOf('mall-stage') > -1) { // 预生产
  thirdPartyDomain = 'https://mall-stage.gwm.co.th';
  storeInfo = {
    testDriveStoreId: '1', // 体验店
    testDriveStore: 'GWM Central Bangna 体验店',
    deliveryStoreId: '1', // 服务店
    deliveryStore: 'GWM Central Bangna 体验店',
  }; // 预生产、生产 store信息
  YMChatBotID = 'x1616165706679';
  firebaseApiKey = 'AIzaSyBaZeHT8qTxxsI_1aDPM20qdWOabpOc8qw';
  firebaseProjectId = 'gwm-test';
  firebaseProjectNumber = '996210922725';
  firebaseMessagingSenderId = '996210922725';
  firebaseAppId = '1:996210922725:web:5ab21838339802935db00c';
  firebaseMeasurementId = 'G-M3Y2PJ9R1Y';

} else { // 生产环境
  thirdPartyDomain = 'https://mall.gwm.co.th';
  storeInfo = {
    testDriveStoreId: '1',  // 体验店
    testDriveStore: 'GWM Central Bangna 体验店',
    deliveryStoreId: '1', // 服务店
    deliveryStore: 'GWM Central Bangna 体验店'
  }; // 预生产、生产 store信息
  YMChatBotID = 'x1616418418416';
  firebaseApiKey = 'AIzaSyAE3aAPO-eCMzkIIFsVsa3BNJ47XTKDJ7c';
  firebaseProjectId = 'gwmapp-1b43f';
  firebaseProjectNumber = '271093531349';
  firebaseMessagingSenderId = '271093531349';
  firebaseAppId = '1:271093531349:web:6118a582f213d984bd985c';
  firebaseMeasurementId = 'G-M66C7M6DNJ';
}
let gtmId = 'GTM-PS4DXK9'; // google tag manager https://tagmanager.google.com/   User: MKTGWMTH@gmail.com

let facebookPixelId = '1144726672634358'; // facebook piexlID
export { countryShortcut };
export default {
  officialDomain,
  thirdPartyDomain,
  storeInfo,
  gtmId,
  facebookPixelId,
  YMChatBotID,
  firebaseApiKey,
  firebaseProjectId,
  firebaseProjectNumber,
  firebaseMessagingSenderId,
  firebaseAppId,
  firebaseMeasurementId
};
