import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as App from './app/reducer';
import * as Vehicle from './orderVehicle/reducer';

const store = createStore(
  combineReducers({ ...App, ...Vehicle }),
  applyMiddleware(thunk)
);

export default store;
