import { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { getUserBaseInfo } from '@/api/user';
import { routes } from '.';

export const getRouteInfo = (route, targetPath) => {
  for (const element of route) {
    let currentPath = element.path;
    let targetBak = targetPath;
    if (currentPath.indexOf(':') > -1) {
      targetBak = targetBak.split('/');
      targetBak.pop();
      targetBak = targetBak.join('/') + '/';
      currentPath = currentPath.split(':');
      currentPath.pop();
      currentPath = currentPath[0];
    }
    if (currentPath === targetBak) {
      return element;
    }
    if (element.routes && element.routes.length > 0) {
      const result = getRouteInfo(element.routes, targetPath);
      if (result) {
        return result;
      }
    }
  }
};

const RouterPrompt = () => {
  // 获取当前跳转目标路由信息
  const [location, setLocation] = useState();
  const [action, setAction] = useState();
  const [canLeave, setCanLeave] = useState(false);
  const history = useHistory();
  const [promptWhen, setPromptWhen] = useState(true);

  const checkToken = () => {
    // 在api拦截器那边已经做处理了，这边实际逻辑只走code为000000的情况
    getUserBaseInfo()
      .then((res) => {
        if (res.code === '000000') {
          setPromptWhen(false);
        } else {
          setCanLeave(true);
          window.localStorage.removeItem('loginData');
          window.localStorage.removeItem('userBaseInfo');
          history.push(`/login?from=${window.location.pathname + window.location.search}`);
        }
      })
      .catch((res) => {
        setCanLeave(true);
        window.localStorage.removeItem('loginData');
        window.localStorage.removeItem('userBaseInfo');
        history.push(`/login?from=${window.location.pathname + window.location.search}`);
      });
  };

  const leaveConfirm = (locationInfo, actionType) => {
    // 不处理POP时的token校验
    if (!canLeave && actionType !== 'POP') {
      setAction(actionType);
      setLocation(locationInfo);
      return false;
    }
    setCanLeave(false);
    return true;
  };

  useEffect(() => {
    if (location) {
      const routeInfo = getRouteInfo(routes, location.pathname)
        ? getRouteInfo(routes, location.pathname)
        : routes.find((item) => {
          return item.path === '*';
        });
      if (routeInfo.requiresAuth) {
        checkToken();
      } else {
        setPromptWhen(false);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!promptWhen) {
      toTargetRoute();
    }
  }, [promptWhen]);

  const toTargetRoute = () => {
    setCanLeave(false);
    switch (action) {
      case 'PUSH':
        history.push(location);
        break;
      case 'POP':
        // 不处理POP时的token校验
        break;
      case 'REPLACE':
        history.replace(location);
        break;
      default:
        history.push(location);
        break;
    }
    setPromptWhen(true);
  };

  return <Prompt when={promptWhen} message={leaveConfirm}></Prompt>;

};

export default RouterPrompt;
