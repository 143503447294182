/**
 * @Title: 移动端头部展开菜单
 * @Author: 宁鑫 Alan
 * @Despricetion:
 * @Date: 2021/5/19 14:38
 * @Param:
 *  officialWebsite, // logo对应的URL
 logStatus,  // 当前登录状态值
 showLogoutConfirm, // 退出登录的二次弹窗展开方法
 **/

import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Drawer } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { matchUrlImg } from '@/utils/index';
import logo from '@/assets/__img__/header/gwm_logo.png';
import PropTypes from 'prop-types';

const closeBtnIco = matchUrlImg(`header/close_btb.png`);

const DrawerComponent = forwardRef((props, cRef) => {

  const history = useHistory();

  const {
    officialWebsite,
    logStatus,
    showLogoutConfirm,
  } = props;
  let location = useLocation();
  let { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false); // 菜单打开状态

  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(cRef, () => ({
    // 这里 就是暴露给父组件的方法
    childOnClose: () => {
      onClose();
    },
    childShowDrawer: () => {
      showDrawer();
    }
  }));
  const onClose = () => {
    setShowMenu(false);
  };
  const showDrawer = () => {
    setShowMenu(true);
  };
  return (
    <Fragment>
      {/* 下拉菜单 */}
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={showMenu}
        getContainer={() => document.getElementById('phone_nav')}
        className={`mobile_dropDown ${showMenu ? 'droped_menu' : 'hideDropDown'}`}
        bodyStyle={{ width: '100%' }}
        style={{ width: '100%' }}
        zIndex={10}
        mask={false}
        destroyOnClose={true}
      >
        <div className="dropDown_item dropDown_item1">
          <a href={officialWebsite} className="dropDown_logo">
            <img className="logoImg" src={logo} alt="Logo" draggable="false" />
          </a>
          <img src={closeBtnIco} alt="X" className="dropDown_closeBtn" onClick={() => onClose()} draggable="false" />
        </div>
        {
          (location.pathname !== '/' && location.pathname !== '/login')
            ?
            (<div className="dropDown_item" onClick={() => { history.push('/accountInfo'); setShowMenu(false) }}>
              {t('MY_ACCOUNT')}
            </div>)
            :
            ''
        }
        {
          (location.pathname !== '/' && location.pathname !== '/login')
            ?
            (
              <div className="dropDown_item dropDown_item2" onClick={() => showLogoutConfirm()}>
                【{logStatus ? t('LOGOUT') : t('SIGN_UP_LOGIN')}】
              </div>
            )
            :
            ''
        }
      </Drawer>
    </Fragment>
  );
});

DrawerComponent.propTypes = {
  officialWebsite: PropTypes.string,
  logStatus: PropTypes.bool,
  showLogoutConfirm: PropTypes.func,
};

DrawerComponent.defaultProps = {
  officialWebsite: '',
  logStatus: false,
  showLogoutConfirm: () => {
    return false;
  },
};

export default DrawerComponent;

