import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
const FooterComponent = () => {
  const { app } = useSelector((state) => ({ app: state.app }));
  let { t } = useTranslation();
  let location = useLocation();
  const needFooter = () => {
    const noFooter = [
      '/',
      '/login',
      '/ordervehicles/normal',
      '/ordervehicles/vipbuy',
      '/payment',
      '/newordervehicles',
      '/ordervehiclesmodify',
      '/testdrive',
      '/storetestdrive',
      '/cloudshowroom',
      '/undermaintenance'
    ];
    if (!noFooter.includes(location.pathname.toLowerCase())) {
      if (
        location.pathname.toLowerCase().indexOf('/orderconfirm/') > -1 ||
        (app.ua !== 'pc' && (location.pathname.toLowerCase().indexOf('/accountinfo') > -1 || location.pathname.toLowerCase().indexOf('/comment') > -1)) ||
        (app.ua === 'phone' && location.pathname.toLowerCase().indexOf('/testdrivedetail') > -1)
      ) {
        return false;
      }
      return true;
    }
    return false;
  };
  return (
    needFooter() && (
      <footer className="footer-wrapper">
        <p>{t('COPYRIGHT')}</p>
      </footer>
    )
  );
};
export default FooterComponent;
