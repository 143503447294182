import * as type from '../type';
import { getUserBaseInfo } from '@/api/user';

export function getUa(ua) {
  return {
    type: type.UA,
    payload: { ua },
  };
}

export function setStoreUserInfo(callback) {
  return dispatch => {
    getUserBaseInfo().then(({
      code,
      data
    }) => {
      if (code === '000000') {
        dispatch(setUserInfo(data));
        dispatch(setUserBaseInfo(data));
        window.localStorage.setItem('userBaseInfo', JSON.stringify(data));
      }
      if (callback) {
        callback(code);
      }
    }).catch(({ code }) => {
      if (callback) {
        callback(code);
      } else {
        setStoreUserInfo();
      }
    });
  };
}

export function setUserInfo(data) {
  return {
    type: type.USER_INFO,
    payload: data
  };
}

export function setUserBaseInfo(userBaseInfo) {
  return {
    type: type.USER_BASE_INFO,
    payload: { userBaseInfo }
  };
}

export function setFirebaseReducer(data) {
  return {
    type: type.FIREBASE,
    payload: data
  };
}
