// mark：26个接口
import request, {
  gateway
} from './index';
// 测试接口
export const getList = (data, options) => {
  return request.get(
    `${gateway}bookTestDrive/queryDetailByOrderNumber`,
    data,
    options
  );
};
// 获取手机验证码
export const getSMSCode = (data, options) => {
  return request.post(`${gateway}userAuth/getSMSCode`, data, options);
};
// 获取手机验证码2（获取手机验证码1如果走不通，就用这个2来给用户发送短信，韩铭让改的）
export const loginGetSMSCode = (data, options) => {
  return request.post(`${gateway}userAuth/loginGetSMSCode`, data, options);
};
// 验证手机号是否存在
export const checkAccount = (data, options) => {
  return request.post(`${gateway}userAuth/checkAccount`, data, options);
};
// 短信登录
export const loginWithSMS = (data, options) => {
  return request.post(`${gateway}userAuth/loginWithSMS`, data, options);
};
// 设置密码
export const firstSetPassword = (data, options) => {
  return request.post(`${gateway}userAuth/firstSetPassword`, data, options);
};
// 修改 密码时，短信验证
export const checkSMSCode = (data, options) => {
  return request.post(`${gateway}userAuth/checkSMSCode`, data, options);
};
// 修改用户账户信息
export const updateUserInfo = (data, options) => {
  return request.post(`${gateway}userAuth/updateUserInfo`, data, options);
};
// 账号密码登录
export const loginAccount = (data, options) => {
  return request.post(`${gateway}userAuth/loginAccount`, data, options);
};
// 修改密码
export const updatePassword = (data, options) => {
  return request.post(`${gateway}userAuth/updatePassword`, data, options);
};
// 修改邮箱
export const changeEmail = (data, options) => {
  return request.post(`${gateway}user/changeEmail`, data, options);
};
// 协议
export const getAgreementContent = (data, options) => {
  return request.get(`${gateway}agreement/getAgreementContent`, data, options);
};
// 退出登录
export const logout = (data, options) => {
  return request.get(`${gateway}userAuth/logout`, data, options);
};
// 第三方登录
export const thirdPartyCheck = (data, options) => {
  return request.post(`${gateway}user/tripartiteLoing`, data, options);
};
// 获取用户信息
export const getUserBaseInfo = (data, options) => {
  return request.get(`${gateway}user/getUserBaseInfo`, data, options);
};
// 绑定第三方登录
export const bindTripartite = (data, options) => {
  return request.post(`${gateway}user/bindTripartite`, data, options);
};
// 注销申请撤回
export const withdraw = (data, options) => {
  return request.post(`${gateway}userAuth/withdraw`, data, options);
};
// 切换语言
export const switchLanguage = (data, options) => {
  return request.post(`${gateway}userAuth/switchLanguage`, data, options);
};
// 簽署協議
export const signProtocolNew = (data, options) => {
  return request.post(`${gateway}userAuth/signProtocolNew`, data, options);
};

// 簽署協議
export const getCountrys = (data, options) => {
  return request.get(`${gateway}country/getCountrys`, data, options);
};
// 金融计算器部分

// 查询金融方案绑定的车型类型

export const queryFinalCarModelList = (data, options) => {
  return request.get(`${gateway}vehicleShop/financialSolution/queryFinalCarModelList`, data, options);
};
// 查询金融方案
export const queryFinancialSolutionInfoByModelCode = (data, options) => {
  return request.get(`${gateway}vehicleShop/financialSolution/queryFinancialSolutionInfoByModelCode`, data, options);
};
// 查询第一条品牌和车系
export const queryOneBrandAudi = (data, options) => {
  return request.get(`${gateway}carModelContent/queryOneBrandAudi`, data, options);
};
// 创建金融留资数据
export const createRetainCapital = (data, options) => {
  return request.post(`${gateway}vehicleShop/retainCapital/createRetainCapital`, data, options);
};
// 根据车型code贷款期限首付比例计算金融方案
export const queryByModelCode = (data, options) => {
  return request.get(`${gateway}vehicleShop/financialSolution/queryByModelCode`, data, options);
};
// 车型详细查询
export const getDetailsList = (data, options) => {
  return request.get(`${gateway}carModelContent/getDetailsList`, data, options);
};