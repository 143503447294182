import React from 'react';
import { Modal, Button } from 'antd';
import { useSelector } from 'react-redux';
import './index.scss';
import { useTranslation } from 'react-i18next';
import close from '@/assets/__img__/login/close.png';
import PropTypes from 'prop-types';

const PromptBox = props => {
  const {
    title, // 标题
    onOkCalback, // 确认按钮回调
    btnOKTxt, // 确认按钮文字
    btnCancelTxt, // 取消按钮文字
    tips, // 提示框内容
    secondTips, // 提示框内容(换行部分)
    visible, // modal框显示隐藏
    setModalVisible, // 控制modal框显示隐藏
    noCancelBtn, // modal框的footer部分
    loading, // 确认按钮的loading
    footer, // modal框的footer部分
    closable, // 是否需要展示关闭X按钮 该值要和maskClosable一起设置
    maskClosable, // 是否允许点击蒙层关闭弹窗 该值要和closable一起设置
  } = props;

  // 判断当前所持设备类型
  const { app } = useSelector((state) => ({
    app: state.app
  }));

  let { t } = useTranslation();

  const handleOk = () => {
    onOkCalback();
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  const modalWidth = app.ua === app.isPc ? 500 : app.ua === app.isPad ? 'calc(100vw - 300px)' : 300;

  return (
    <Modal
      className={`modal-prompt-box-${app.ua}`}
      title={title || null}
      closeIcon={<img src={close} alt="visiable" draggable="false" />}
      visible={visible}
      closable={closable}
      maskClosable={maskClosable}
      width={modalWidth}
      onOk={handleOk}
      centered
      footer={null}
      onCancel={handleCancel}
    >
      <p className="prompt-box-modal-tip">{tips || 'Some contents...'}</p>
      <p className="prompt-box-modal-tip">{secondTips || ''}</p>
      <div className={`prompt-box-modal-button ${noCancelBtn ? 'no-cancel-btn' : ''}`}>
        {
          footer || (
            <>
              {
                !noCancelBtn ? (
                  <Button onClick={handleCancel}>{btnCancelTxt || t('CANCEL')}</Button>
                ) : null
              }
              <Button
                className="prompt-box-open-button"
                loading={loading || false}
                onClick={handleOk}
              >
                {btnOKTxt || t('OK')}
              </Button>
            </>
          )
        }
      </div>
    </Modal>
  );
};

PromptBox.prototype = {
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
};

PromptBox.defaultProps = {
  closable: true,
  maskClosable: true
};

export default PromptBox;
