import * as type from '../type';

export function getCarModelContent(data) {
  return {
    type: type.CAR_MODEL,
    payload: data
  };
}

export function getExteriorContent(data) {
  return {
    type: type.EXTERIOR,
    payload: data
  };
}

export function getInteriorContent(data) {
  return {
    type: type.INTERIOR,
    payload: data
  };
}

export function getSpuContent(data) {
  return {
    type: type.SPU,
    payload: data
  };
}

export function setExperienceCenter(data) {
  return {
    type: type.EXPER_CENTER,
    payload: data
  };
}
export function setPickCarInfo(data) {
  return {
    type: type.SET_PICK_CAR_INFO,
    payload: data
  };
}
export function setPickCarUserInfo(data) {
  return {
    type: type.SET_PICK_CAR_USER_INFO,
    payload: data
  };
}
// 金融计算器计算结果
export function setfinancialData(data) {
  return {
    type: type.FINANCIAL_DATA,
    payload: data
  };
}
// 选择车型的所有值
export function setAllCarData(data) {
  return {
    type: type.ALLCAR_DATA,
    payload: data
  };
}
// 车型价格
export function setSellingPriceData(data) {
  return {
    type: type.SELLINGPRICE_DATA,
    payload: data
  };
}
// 订购的各个参数
export function setParamsType(data) {
  return {
    type: type.ORDER_PARAMS_TYPE,
    payload: data
  };
}
// banner 图
export function setBannerList(data) {
  return {
    type: type.BANNER_LIST,
    payload: data
  };
}
// 金融计算器选择结果
export function setSelectResults(data) {
  return {
    type: type.SELECT_RESULTS,
    payload: data
  };
}
// 查询车辆详细信息
export function setCarDetail(data) {
  return {
    type: type.CAR_DETAIL,
    payload: data
  };
}
// 金融描述信息
export function setFinancalDescription(data) {
  return {
    type: type.FINANCAL_DESCRIPTION,
    payload: data
  };
}
// 秒杀文字 labelContent

export function setLabelContent(data) {
  return {
    type: type.LABEL_CONTENT,
    payload: data
  };
}
// 秒杀倒计时 countDown
export function setCountDown(data) {
  return {
    type: type.COUNT_DOWN,
    payload: data
  };
}
// 秒杀 限购数量
export function setPurchaseLimitationNumber(data) {
  return {
    type: type.PURCHASE_LIMITATION,
    payload: data
  };
}
// 是否是秒杀
export function setSeckillType2(data) {
  return {
    type: type.SECKILL_TYPE,
    payload: data
  };
}
// 秒杀商品
export function setSkuStockVOList(data) {
  return {
    type: type.SKUSTOCK_LIST,
    payload: data
  };
}
// 秒杀 取消时间
export function setMarketInfoDto(data) {
  return {
    type: type.MARKET_INFODTO,
    payload: data
  };
}

export function setCarSettingStatus(data) {
  return {
    type: type.EXTERIOR_STATUS,
    payload: data
  };
}

export function setSmsObj(data) {
  return {
    type: type.SMSCODE,
    payload: data
  };
}

export function setPurchasePhone(data) {
  return {
    type: type.PURCHASE_PHONE,
    payload: data
  };
}

export function setSpecsStatus(data) {
  return {
    type: type.SPECS_STATUS,
    payload: data
  };
}

export function setSellingPrice(data) {
  return {
    type: type.SELLING_PRICE,
    payload: data
  };
}
export function setSubsidyPrice(data) {
  return {
    type: type.SUBSIDY_PRICE,
    payload: data
  };
}
export function setSpecialPrice(data) {
  return {
    type: type.SPECIAL_PRICE,
    payload: data
  };
}
export function setVehicleTabFn(data) {
  return {
    type: type.VEHICLE_TAB_FN,
    payload: data
  };
}
export function setVehicleTabIndex(data) {
  return {
    type: type.VEHICLE_TAB_INDEX,
    payload: data
  };
}
export function setVehicleTabVisible(data) {
  return {
    type: type.VEHICLE_TAB_VISILBLE,
    payload: data
  };
}
export function setVehicleTabDisplay(data) {
  return {
    type: type.VEHICLE_TAB_DISPLAY,
    payload: data
  };
}

export const actionToDo = (type, data, willDoSomething) => {
  if (willDoSomething) {
    return willDoSomething();
  }
  return {
    type: type[type],
    payload: data
  };
};

export const setVehicleTabsFrozen = (data) => {
  return {
    type: type.VEHICLE_TABS_FROZEN,
    payload: data
  };
};
