/**
 * @Title: 语言切换组件
 * @Author: 宁鑫 Alan
 * @Despricetion: i18next 切换语言
 * @Date: 2021/5/19 11:59
 * @Param: langList: 语言列表 例如
 *            [
 *             { id: 2, lang: "en", txt: "ENGLISH" },
 *             { id: 1, lang: "s_line", txt: "/" },
 *             { id: 0, lang: "th", txt: 'THAI' },
 *           ]
 **/

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { switchLanguage } from '@/api/user';
import { useTranslation } from 'react-i18next';
import './index.scss';
import PropTypes from 'prop-types';

function SwitchLang(props) {
  let { i18n } = useTranslation();
  const {
    ua,
    userBaseInfo
  } = useSelector((state) => ({
    ua: state.app.ua, // 获取登陆时保存的用户信息
    userBaseInfo: state.app.userBaseInfo // 获取登陆时保存的用户信息
  }));
  const { defaultLangList } = props;
  let location = useLocation();

  // 语言切换功能
  const switchLocale = (lang) => {
    i18n.changeLanguage(lang);
    if (lang === 'th') {
      document.getElementById('gwm-container').className = 'thi-container';
    } else {
      document.getElementById('gwm-container').className = '';
    }
    if (location.pathname !== '/' && location.pathname !== '/login' && userBaseInfo) {
      switchLanguage({}).then((res) => {
        return false;
      })
        .catch((res) => {
          return false;
        });
    }
  };

  return (
    <div className={`lang_area ${ua}_lang_area`}>
      {
        defaultLangList.map((item, index) =>
          ((index % 2 !== 0)
            ?
            (<span className={`${item.lang} lang_option`}
              key={index}>{item.txt}</span>)
            :
            (<span className={`${item.lang} lang_option ${i18n.language === item.lang ? 'active_lang' : ''}`}
              key={index} onClick={() => switchLocale(item.lang)}>{item.txt}</span>))
        )
      }
    </div>
  );
}

SwitchLang.propTypes = {
  defaultLangList: PropTypes.array
};

SwitchLang.defaultProps = {
  defaultLangList: [
    {
      id: 2,
      lang: 'en',
      txt: 'ENGLISH'
    },
    {
      id: 1,
      lang: 's_line',
      txt: '/'
    },
    {
      id: 0,
      lang: 'th',
      txt: 'THAI'
    },
  ]
};

export default SwitchLang;
