import * as type from '../type';

const defaultStates = {
  model: {},
  exterior: {},
  interior: {},
  spu: {},
  getExCenter: {},
  getPickCarInfo: {},
  getPickCarUserInfo: {},
  getfinancialData: {},
  getAllCarData: {},
  getSellingPriceData: {},
  getSelectResults: {}, // 金融计算器选择结果
  getCarDetail: [], // 车辆详细信息
  getFinancalDescription: '', // 金融信息描述
  getLabelContent: {}, // 秒杀文字
  getCountDown: {}, // 秒杀倒计时
  getPurchaseLimitationNumber: '', // 秒杀限购数量
  getSeckillType: null, // 是不是秒杀
  getMarketInfoDto: {}, // 秒杀 取消时间
  getSkuStockVOList: [],
  MutexData: [],
  getBannerList: {},
  exteriorStatus: true, // 当前以外饰为基准点
  smsCode: '',
  purchasePhone: false,
  specsStatus: '',
  getParamsType: {
    name: false,
    phone: false
  },
  getSellingPrice: '', // 销售价
  getSubsidyPrice: '', // 政府补贴价
  // 优惠价
  getSpecialPrice: '',
  vehicleTabFn: () => {},
  vehicleTabIndex: 1,
  vehicleTabVisible: false, // tab页是否可点击（默认不可点击，需接口有返回后方可点击）
  vehicleTabsFrozen: false,
  vehicleTabDisplay: true // tab页title是否显示
};

export const carModel = (state = defaultStates.model, action = {}) => {
  switch (action.type) {
    case type.CAR_MODEL:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const exterior = (state = defaultStates.exterior, action = {}) => {
  switch (action.type) {
    case type.EXTERIOR:
      return action.payload;
    default:
      return state;
  }
};

export const interior = (state = defaultStates.interior, action = {}) => {
  switch (action.type) {
    case type.INTERIOR:
      return action.payload;
    default:
      return state;
  }
};

export const mutex = (state = defaultStates.MutexData, action = {}) => {
  switch (action.type) {
    case type.Mutex:
      return [...action.payload];
    default:
      return state;
  }
};

export const exteriorStatus = (state = defaultStates.exteriorStatus, action = {}) => {
  switch (action.type) {
    case type.EXTERIOR_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export const spu = (state = defaultStates.spu, action = {}) => {
  switch (action.type) {
    case type.SPU:
      return action.payload;
    default:
      return state;
  }
};

export const getExCenter = (state = defaultStates.getExCenter, action = {}) => {
  switch (action.type) {
    case type.EXPER_CENTER:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

export const getPickCarInfo = (state = defaultStates.getPickCarInfo, action = {}) => {
  switch (action.type) {
    case type.SET_PICK_CAR_INFO:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

export const getPickCarUserInfo = (state = defaultStates.getPickCarUserInfo, action = {}) => {
  switch (action.type) {
    case type.SET_PICK_CAR_USER_INFO:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 计算器结果
export const getfinancialData = (state = defaultStates.getfinancialData, action = {}) => {
  switch (action.type) {
    case type.FINANCIAL_DATA:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 选择车型结果
export const getAllCarData = (state = defaultStates.getAllCarData, action = {}) => {
  switch (action.type) {
    case type.ALLCAR_DATA:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// banner 图
export const getBannerList = (state = defaultStates.getBannerList, action = {}) => {
  switch (action.type) {
    case type.BANNER_LIST:
      return action.payload;
    default:
      return state;
  }
};
// 金融计算器选择结果
export const getSelectResults = (state = defaultStates.getSelectResults, action = {}) => {
  switch (action.type) {
    case type.SELECT_RESULTS:
      return action.payload;
    default:
      return state;
  }
};
// 查询车辆详细信息 setCarDetail
export const getCarDetail = (state = defaultStates.getCarDetail, action = {}) => {
  switch (action.type) {
    case type.CAR_DETAIL:
      return action.payload;
    default:
      return state;
  }
};
// 金融信息描述
export const getFinancalDescription = (state = defaultStates.getFinancalDescription, action = {}) => {
  switch (action.type) {
    case type.FINANCAL_DESCRIPTION:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 秒杀文字
export const getLabelContent = (state = defaultStates.getLabelContent, action = {}) => {
  switch (action.type) {
    case type.LABEL_CONTENT:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

// 秒杀倒计时
export const getCountDown = (state = defaultStates.getCountDown, action = {}) => {
  switch (action.type) {
    case type.COUNT_DOWN:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 秒杀 取消时间
export const getMarketInfoDto = (state = defaultStates.getMarketInfoDto, action = {}) => {
  switch (action.type) {
    case type.MARKET_INFODTO:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 秒杀 限购数量
export const getPurchaseLimitationNumber = (state = defaultStates.getPurchaseLimitationNumber, action = {}) => {
  switch (action.type) {
    case type.PURCHASE_LIMITATION:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 是不是秒杀
export const getSeckillType = (state = defaultStates.getSeckillType, action = {}) => {
  switch (action.type) {
    case type.SECKILL_TYPE:
      return action.payload;
    default:
      return state;
  }
};
// setSkuStockVOList
export const getSkuStockVOList = (state = defaultStates.getSkuStockVOList, action = {}) => {
  switch (action.type) {
    case type.SKUSTOCK_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

// 车型价格sellingPrice
export const getSellingPriceData = (state = defaultStates.getSellingPriceData, action = {}) => {
  switch (action.type) {
    case type.SELLINGPRICE_DATA:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
// 订购各参数是否已填
export const getParamsType = (state = defaultStates.getParamsType, action = {}) => {
  switch (action.type) {
    case type.ORDER_PARAMS_TYPE:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

export const getSmsCode = (state = defaultStates.smsCode, action = {}) => {
  switch (action.type) {
    case type.SMSCODE:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
export const getPurchasePhone = (state = defaultStates.purchasePhone, action = {}) => {
  switch (action.type) {
    case type.PURCHASE_PHONE:
      return action.payload;
    default:
      return state;
  }
};
export const specsStatus = (state = defaultStates.specsStatus, action = {}) => {
  switch (action.type) {
    case type.SPECS_STATUS:
      return action.payload;
    default:
      return state;
  }
};
export const getSellingPrice = (state = defaultStates.getSellingPrice, action = {}) => {
  switch (action.type) {
    case type.SELLING_PRICE:
      return action.payload;
    default:
      return state;
  }
};
export const getSubsidyPrice = (state = defaultStates.getSubsidyPrice, action = {}) => {
  switch (action.type) {
    case type.SUBSIDY_PRICE:
      return action.payload;
    default:
      return state;
  }
};
export const getSpecialPrice = (state = defaultStates.getSpecialPrice, action = {}) => {
  switch (action.type) {
    case type.SPECIAL_PRICE:
      return action.payload;
    default:
      return state;
  }
};
export const vehicleTabFn = (state = defaultStates.vehicleTabFn, action = {}) => {
  switch (action.type) {
    case type.VEHICLE_TAB_FN:
      return action.payload;
    default:
      return state;
  }
};
export const vehicleTabIndex = (state = defaultStates.vehicleTabIndex, action = {}) => {
  switch (action.type) {
    case type.VEHICLE_TAB_INDEX:
      return action.payload;
    default:
      return state;
  }
};
export const vehicleTabVisible = (state = defaultStates.vehicleTabVisible, action = {}) => {
  switch (action.type) {
    case type.VEHICLE_TAB_VISILBLE:
      return action.payload;
    default:
      return state;
  }
};
export const vehicleTabDisplay = (state = defaultStates.vehicleTabDisplay, action = {}) => {
  switch (action.type) {
    case type.VEHICLE_TAB_DISPLAY:
      return action.payload;
    default:
      return state;
  }
};

export const vehicleTabsFrozen = (state = defaultStates.vehicleTabsFrozen, action = {}) => {
  switch (action.type) {
    case type.VEHICLE_TABS_FROZEN:
      return action.payload;
    default:
      return state;
  }
};
